<template>
  <iframe
    id="JFL_223456583171155"
    title="Submit Confidential Feedback"
    onload="window.parent.scrollTo(0,0)"
    allowtransparency="true"
    allowfullscreen="true"
    allow="geol ocation; microphone; camera"
    src="https://form.jotform.com/223456583171155"
    frameborder="0"
    style="min-width: 100%; height:539px; border:none;" scrolling="no">
  </iframe>
</template>

<script>
export default {
  name: 'SubmitConfidentialFeedback',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
#JFL_223456583171155 {
  height: 942px!important;
  display: block;
}
</style>
